import format from "date-fns/format";

export default function useDateFormatter() {
  const endYear = new Date().getFullYear() - 18;
  const startYear = endYear - 102;
  const middleDate = new Date(Math.floor((endYear + startYear) / 2), 1, 1);

  return {
    endYear,
    startYear,
    middleDate,
    dateFormats: {
      withTime: "dd MMM yyyy HH:mm",
    },
    formatDate(date: Date | string, template = "dd MMM yyyy") {
      if (!date || date === "0001-01-01T00:00:00+00:00") return "";

      if (date instanceof Date) {
        return format(date, template);
      }

      return format(new Date(date), template);
    },
  };
}
